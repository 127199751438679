<script setup lang="ts">

</script>

<template>
  <div class="mt-8">
    <div class="d-flex justify-content-center mb-10">
      <i class="bi bi-check-circle icon"></i>
    </div>
    <h2 class="text-center mb-6">Thanks for your singature!</h2>
    <p class="fs-6 text-center">Your payment has been confirmed successfully! You are now an official member of the Hacking Club and have unlimited access to all of the platform’s features and content.<br>
Feel free to explore all the labs, lessons, courses, and exclusive information available to you as a member. <br>
Hacking Club is committed to offering you the most up-to-date and relevant information in the field of hacking, helping you improve your skills and knowledge. Make the most of your membership by exploring all the benefits and advantages that are now within your reach.<br>
If you have any questions or require additional assistance, please do not hesitate to contact us. We are here to support you at every step of your journey into the world of hacking.<br>
Welcome to Hacking Club, we’re excited to have you with us!</p>
  </div>
</template>

<style scoped lang="scss">
.icon{
  font-size: 64px;
  color: rgb(99, 59, 188);
}
</style>